import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { ICertificate } from '@dltru/dfa-models'
import { Box, LoadingOutlined, Tabs, UserCertificateItem } from '@dltru/dfa-ui'

import { transactionDetailsSelectors } from '@store/sign/selectors'

import style from './style.m.less'

enum TabName {
    json = 'JSON',
    rpl = 'RPL',
}
const defaultTab = TabName.json

interface IComponentProps {
    selectedCertificate: ICertificate
}

const LoadingDetails = () => (
    <Box align="center" justify="center" height={'100%'}>
        <LoadingOutlined style={{ fontSize: 36 }} />
    </Box>
)

const TransactionDetails = ({ selectedCertificate }: IComponentProps) => {
    const details = useSelector(transactionDetailsSelectors.selectTransaction)
    const isLoadingDetails = useSelector(transactionDetailsSelectors.selectIsLoading)
    const [activeTab, setActiveTab] = useState<string>(defaultTab)

    const switchRepresentation = useCallback((tab: string) => {
        setActiveTab(tab)
    }, [])

    return (
        <Box>
            <Box>Вы действительно хотите подписать документ электронной подписью?</Box>
            <UserCertificateItem
                key={selectedCertificate.skid}
                certificate={selectedCertificate}
                isActive={false}
            />
            <Tabs onChange={switchRepresentation} activeKey={activeTab} style={{ border: 'none' }}>
                <Tabs.TabPane tab={TabName.json} key={TabName.json}>
                    <Box className={style.transactionBox} padding={10}>
                        {isLoadingDetails ? (
                            <LoadingDetails />
                        ) : details ? (
                            <div>
                                <pre>{JSON.stringify(details.json, null, 2)}</pre>
                            </div>
                        ) : (
                            'Для просмотра деталей транзакции необходимо выбрать сертификат'
                        )}
                    </Box>
                </Tabs.TabPane>
                <Tabs.TabPane tab={TabName.rpl} key={TabName.rpl}>
                    <Box className={style.transactionBox} padding={10}>
                        {isLoadingDetails ? (
                            <LoadingDetails />
                        ) : details ? (
                            details.Rpl
                        ) : (
                            'Для просмотра деталей транзакции необходимо выбрать сертификат'
                        )}
                    </Box>
                </Tabs.TabPane>
            </Tabs>
        </Box>
    )
}

export default TransactionDetails
