// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".investWrapper_BNZyk {\n  margin: 100px auto;\n}\n.card_SfrSV {\n  width: 610px;\n  min-height: 768px;\n  padding: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Invest/style.m.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;AAAF","sourcesContent":[".investWrapper {\n  margin: 100px auto;\n}\n\n.card {\n  width: 610px;\n  min-height: 768px;\n  padding: 0 !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"investWrapper": "investWrapper_BNZyk",
	"card": "card_SfrSV"
};
export default ___CSS_LOADER_EXPORT___;
