import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Box, Card, Divider } from '@dltru/dfa-ui'

import { loadOrder, prepareTransaction, signTransaction } from '@store/order'
import { orderSelectors } from '@store/order/selectors'

import { ConditionsOrder } from '@pages/Invest/components/ConditionsOrder'
import { InvestFooter } from '@pages/Invest/components/InvestFooter'
import { InvestHeader } from '@pages/Invest/components/InvestHeader'
import { InvestOrder } from '@pages/Invest/components/InvestOrder'

import { CertificateListModalWithTransactionData } from '@components/Certificate/CertificateListModalWithTransactionData'

import style from './style.m.less'

const Invest: FC = () => {
    const { investId } = useParams()
    const [openConditions, setOpenConditions] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const isLoading = useSelector(orderSelectors.selectOrderLoading)
    const orderData = useSelector(orderSelectors.selectOrder)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (investId) {
            reduxDispatch(loadOrder(investId))
        }
    }, [])

    if (isLoading || !orderData) {
        return null
    }

    const onSubscribe = (skid: string) => {
        reduxDispatch(prepareTransaction({ skid }))
    }
    const onSign = (sha1: string) => {
        reduxDispatch(signTransaction({ sha1 }))
    }

    return (
        <div className={style.investWrapper}>
            <Card className={style.card}>
                <InvestHeader />
                <Divider margin={0} />
                <TransitionGroup className="slide-animation__block">
                    {!openConditions && (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <div>
                                <Box padding={[32, 40]}>
                                    <InvestOrder order={orderData} />
                                </Box>
                                <Divider margin={0} />
                                <InvestFooter
                                    onSignButton={() => setIsVisibleCertificateModal(true)}
                                    setOpenConditions={setOpenConditions}
                                />
                            </div>
                        </CSSTransition>
                    )}
                    {openConditions && (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <ConditionsOrder setOpenConditions={setOpenConditions} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </Card>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </div>
    )
}

export default Invest
