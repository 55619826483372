import { FC } from 'react'
import ModalContainer from 'react-modal-promise'
import { Route, Routes } from 'react-router-dom'

import Invest from '@pages/Invest/Invest'
import { RootLayout } from '@pages/Layout'

const App: FC = () => (
    <div className="App">
        <Routes>
            <Route path="/" element={<RootLayout />}>
                <Route path="spvb/widget/invest/:investId" element={<Invest />} />
            </Route>
        </Routes>

        <ModalContainer />
    </div>
)

export default App
