import React, { FC } from 'react'

import { Box } from '@dltru/dfa-ui'

import style from './style.m.less'

export const InvestHeader: FC = () => {
    return (
        <Box padding={[32, 40]} className={style.header} direction="row" justify="space-between">
            <img
                src={process.env.PUBLIC_URL + '/images/spvb-logo.svg'}
                draggable={false}
                alt="SPVB logo"
                className={style.spvbLogo}
            />
            <img
                src={process.env.PUBLIC_URL + '/images/mc-logo.svg'}
                alt="MC logo"
                draggable={false}
                className={style.mcLogo}
            />
        </Box>
    )
}
