import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ICertificate, UserCertificateType } from '@dltru/dfa-models'
import { SignUtils } from '@dltru/dfa-sign'
import { Alert, UserCertificateItem } from '@dltru/dfa-ui'

import { mapCertificates } from '@store/certificates'
import { certificateSelectors } from '@store/certificates/selectors'

import TransactionDetails from '@components/Certificate/TransactionDetails'

interface IComponentProps {
    selectedCertificate?: ICertificate
    setSelectedCertificate: (value?: ICertificate) => void
    showTransactionDetails?: boolean
}

export const UserCertificateList: FC<IComponentProps> = ({
    selectedCertificate,
    setSelectedCertificate,
    showTransactionDetails,
}) => {
    const certificates = useSelector(certificateSelectors.selectItems)
    const reduxDispatch = useDispatch()
    const getCertificate = async () => {
        const allCertificates: UserCertificateType[] = await SignUtils.getAllCertificates()
        reduxDispatch(mapCertificates({ allCertificates }))
    }

    useEffect(() => {
        getCertificate()
    }, [])

    useEffect(() => {
        if (certificates?.length === 1) {
            setSelectedCertificate(certificates[0])
        }
    }, [certificates])

    if (certificates === null) {
        return <span>Поиск сертификатов...</span>
    }

    if (certificates?.length === 0) {
        return (
            <Alert showIcon description="Не найдено ни одного сертификата подписи" type="warning" />
        )
    }

    if (showTransactionDetails && selectedCertificate) {
        return <TransactionDetails selectedCertificate={selectedCertificate} />
    }

    return (
        <>
            {certificates.map((item) => (
                <UserCertificateItem
                    key={item.skid}
                    certificate={item}
                    isActive={item.skid === selectedCertificate?.skid}
                    onClick={() => setSelectedCertificate(item)}
                />
            ))}
        </>
    )
}
