import React, { Dispatch, FC, SetStateAction } from 'react'

import { Box, Button, EditSquare, Typography } from '@dltru/dfa-ui'

interface IComponentProps {
    setOpenConditions: Dispatch<SetStateAction<boolean>>
    onSignButton: () => void
}
export const InvestFooter: FC<IComponentProps> = ({ setOpenConditions, onSignButton }) => {
    return (
        <Box padding={[32, 40]} align="center" gap={8}>
            <Box justify="center" direction="row" gap={16}>
                <Button borderRadius={12}>Отклонить</Button>
                <Button
                    borderRadius={12}
                    type="primary"
                    icon={<EditSquare />}
                    onClick={onSignButton}
                >
                    Подписать
                </Button>
            </Box>
            <Typography.Text type="secondary">
                Подписывая заявку, вы соглашаетесь с{' '}
                <Button type="linkText" onClick={() => setOpenConditions(true)}>
                    условиями.
                </Button>
            </Typography.Text>
        </Box>
    )
}
