import { memo } from 'react'
import { Outlet } from 'react-router-dom'

import { Layout as AntdLayout } from '@dltru/dfa-ui'

export const RootLayout = memo(() => {
    return (
        <AntdLayout>
            <Outlet />
        </AntdLayout>
    )
})
