// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_zuEYo h1,\n.title_zuEYo h2 {\n  color: #000514;\n  font-family: Raleway, sans-serif;\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 38px;\n  margin-bottom: 8px;\n}\n.title_zuEYo h2 {\n  margin-bottom: 0;\n}\n.title_zuEYo p {\n  color: #868991;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  margin: 0;\n}\n.details_qNIyF {\n  padding: 0 8px;\n}\nh4 {\n  font-weight: 600;\n}\n.header_UTM0Z {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Invest/components/style.m.less"],"names":[],"mappings":"AACA;;EAGQ,cAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADR;AARA;EAYQ,gBAAA;AADR;AAXA;EAeQ,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AADR;AAKA;EACI,cAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AALJ","sourcesContent":["@import 'style/palette';\n.title {\n    h1,\n    h2 {\n        color: @gray-13;\n        font-family: Raleway, sans-serif;\n        font-size: 30px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 38px;\n        margin-bottom: 8px;\n    }\n    h2 {\n        margin-bottom: 0;\n    }\n    p {\n        color: @gray-7;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        margin: 0;\n    }\n}\n\n.details {\n    padding: 0 8px;\n}\n\nh4 {\n    font-weight: 600;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_zuEYo",
	"details": "details_qNIyF",
	"header": "header_UTM0Z"
};
export default ___CSS_LOADER_EXPORT___;
