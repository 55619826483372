import React, { Dispatch, FC, SetStateAction } from 'react'

import { ArrowLeftPure, Box, Button } from '@dltru/dfa-ui'

import style from './style.m.less'

interface IComponentProps {
    setOpenConditions: Dispatch<SetStateAction<boolean>>
}
export const ConditionsOrder: FC<IComponentProps> = ({ setOpenConditions }) => {
    return (
        <Box padding={[32, 40]}>
            <Box gap={12} direction="row" align="center" margin={[0, 0, 24, 0]}>
                <Button
                    style={{ padding: '4px 6px' }}
                    borderRadius={4}
                    onClick={() => setOpenConditions(false)}
                >
                    <ArrowLeftPure />
                </Button>
                <div className={style.title}>
                    <h2>Условия</h2>
                </div>
            </Box>
            <p>
                Настоящая Заявка на приобретение направляется в целях заключения Сделки размещения в
                отношении ЦФА с Идентификационным номером выпуска ЦФА, указанным в графе «Тикер»
                формы «Детали», в объёме, указанном в графе «Размер инвестиции».
            </p>
            <p>
                Заявка на приобретение считается направленной с момента нажатия кнопки «Подписать».
            </p>
            <p>
                Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в
                значении, предусмотренном правилами информационной системы Общества с ограниченной
                ответственностью «Системы распределенного реестра»
            </p>
        </Box>
    )
}
