import React, { FC } from 'react'

import { IInvestOrder, OrderCurrencyTranslate } from '@dltru/dfa-models'
import { Box, Count, DateTimeCell, Price, LabelRow as Row } from '@dltru/dfa-ui'

import style from './style.m.less'

interface IComponentProps {
    order: IInvestOrder
}
export const InvestOrder: FC<IComponentProps> = ({ order }) => {
    return (
        <Box gap={24} direction="column">
            <div className={style.title}>
                <h1>Заявка на инвестицию</h1>
                <p>Подтверждение УКЭП и отправка в ОИС</p>
            </div>
            <Box direction="column">
                <h4>Детали</h4>
                <Box direction="column" className={style.details}>
                    <Row label="Название эмитента" className="label-row__space">
                        {order.Name}
                    </Row>
                    <Row label="Тикер" className="label-row__space">
                        {order.Ticker}
                    </Row>
                    <Row label="Размер инвестиции" className="label-row__space">
                        <Price light price={(order.Volume / 10 ** 8) * (order.Price / 10 ** 8)} />
                    </Row>
                    <Row label="Цена 1 ЦФА" className="label-row__space">
                        <Price light price={order.Price / 10 ** 8} />
                    </Row>
                    <Row label="Количество ЦФА" className="label-row__space">
                        <Count value={order.Volume / 10 ** 8} unit="шт." />
                    </Row>
                    <Row label="Номинал 1 ЦФА" className="label-row__space">
                        <Price light price={order.Nominal / 10 ** 8} />
                    </Row>
                    <Row label="Размер процентной ставки" className="label-row__space">
                        {Number(order.YieldRate).toFixed(1)}%
                    </Row>
                    <Row label="Валюта" className="label-row__space">
                        {OrderCurrencyTranslate[order.Currency]}
                    </Row>
                </Box>
            </Box>

            <Box direction="column">
                <h4>Расписание</h4>
                <Box direction="column" className={style.details}>
                    <Row label="Дата и время начала сбора заявок" className="label-row__space">
                        {DateTimeCell(order.InvestmentStartDate)}
                    </Row>
                    <Row label="Дата и время окончания сбора заявок" className="label-row__space">
                        {DateTimeCell(order.InvestmentEndDate)}
                    </Row>
                    <Row label="Дата выпуска" className="label-row__space">
                        {DateTimeCell(order.DeliveryDate)}
                    </Row>
                    <Row label="Дата погашения выпуска" className="label-row__space">
                        {DateTimeCell(order.RedemptionDate)}
                    </Row>
                </Box>
            </Box>
        </Box>
    )
}
